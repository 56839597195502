import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";

const containerStyles = {
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "center",
  justifyItems: "center",
  alignItems: "center",
  padding: "1rem  0",
  alignSelf: "center",
};
const Vegetables: React.FunctionComponent = ({ data }: any) => {
  return (
    <>
      <section style={containerStyles}>
        <h1>Prodotti di origine vegetale</h1>
        <div>
          {data?.site.siteMetadata.vegetables.map((item) => {
            return (
              <figure key={item.name}>
                <img src={item.image} alt={item.name} />
                <figcaption>
                  <p>{item.description}</p>
                  <span>{item.price}</span>
                </figcaption>
                <Link replace to={`/shop/prodotto/${item.slug}/`}>
                  visualizza prodotto
                </Link>
                {/* <button
                  className="snipcart-add-item"
                  data-item-id={item.name}
                  data-item-price={item.price}
                  //   data-item-url="/paintings/starry-night"
                  data-item-description={item.description}
                  //   data-item-image="/assets/images/starry-night.jpg"
                  data-item-name={item.name}
                >
                  Aggiungi al carrello
                </button> */}
              </figure>
            );
          })}
        </div>
      </section>
    </>
  );
};
export default Vegetables;
export const query = graphql`
  query CatalogueQuery {
    site {
      siteMetadata {
        vegetables {
          name
          image
          price
          slug
        }
      }
    }
  }
`;
